
import { Component, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { IApp, IUserProfileCreate } from '@/interfaces';
import {
  readApps,
  readLastId,
  readOneUser,
  readSelectedApps,
  readUsers,
  TIMEZONES,
} from '@/store/admin/getters';
import {
  dispatchAssignApps,
  dispatchCreateUser,
  dispatchGetApps,
  dispatchGetSelectedApps,
  dispatchGetUsers,
  dispatchUpdateUser,
} from '@/store/admin/actions';
import { dispatchGetMyApps } from '@/store/apps/actions';

@Component({
  components: {
    draggable,
  },
})
export default class CreateUser extends Vue {
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid e-mail';
    },
    password: (value) => this.id() > 0 || value.trim().length > 0 || 'Required',
    minPassword: (value) =>
      (this.id() > 0 && value.length === 0) ||
      value.length >= 8 ||
      'Min 8 characters',
    confirmPassword: () =>
      this.password1() === this.password2() || 'Password must match',
  };
  private titles = ['profile', 'applications'];
  private timezones = TIMEZONES;

  private userId = 0;
  private user: IUserProfileCreate = {
    full_name: '',
    email: '',
    password: '',
    is_active: true,
    is_superuser: false,
  };
  private confirmPassword = '';
  private timezone = TIMEZONES[19];
  private selectedApps: IApp[] = [];
  private allApps: IApp[] = [];
  private tab = null;
  private valid = false;
  private show1 = false;
  private show2 = false;

  private async beforeMount() {
    this.userId = Number(this.$router.currentRoute.params.id ?? 0);
    if (this.userId !== 0) {
      const users = readUsers(this.$store);
      if (users.length === 0) {
        await dispatchGetUsers(this.$store);
      }

      const user = readOneUser(this.$store)(this.userId)!;
      this.user = {
        full_name: user.full_name,
        email: user.email,
        password: '',
        is_active: user.is_active,
        is_superuser: user.is_superuser,
      };

      await dispatchGetSelectedApps(this.$store, { userId: this.userId });
      this.selectedApps = readSelectedApps(this.$store);
    }

    await dispatchGetApps(this.$store);
    this.allApps = readApps(this.$store).filter(
      (app) => this.selectedApps.findIndex((el) => el.id === app.id) < 0,
    );

    this.onSort();
  }

  private id() {
    return this.userId;
  }

  private password1() {
    return this.user.password;
  }

  private password2() {
    return this.confirmPassword;
  }

  private cancel() {
    this.$router.back();
  }

  private async submit() {
    if ((this.$refs.form as any).validate()) {
      this.user.full_name = this.user.full_name.trim();

      if (this.userId > 0) {
        await dispatchUpdateUser(this.$store, {
          id: this.userId,
          user: this.user,
        });
      } else {
        await dispatchCreateUser(this.$store, this.user);
      }

      const lastId = readLastId(this.$store);
      if (lastId > 0) {
        await dispatchAssignApps(this.$store, {
          userId: lastId,
          apps: this.selectedApps,
        });
        await dispatchGetMyApps(this.$store);
        this.$router.push('/main/admin/users');
      }
    }
  }

  private onSort() {
    this.allApps = this.allApps.sort((a, b) =>
      a.name + a.abbr > b.name + b.abbr ? 1 : -1,
    );
    this.selectedApps = this.selectedApps.sort((a, b) =>
      a.name + a.abbr > b.name + b.abbr ? 1 : -1,
    );
  }
}
